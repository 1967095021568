
export default {
  name: 'IntroLayout',
  head() {
    return {
      title: this.$t('title'),
      ...this.$nuxtI18nSeo(),
    };
  },
};
